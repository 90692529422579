var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BModal', {
    staticClass: "position-relative",
    attrs: {
      "id": "modal-reservation-modify-seat-update",
      "body-class": "px-0 py-50",
      "footer-class": "p-50 position-sticky position-bottom-0 bg-white",
      "no-close-on-backdrop": "",
      "no-enforce-focus": "",
      "centered": "",
      "size": "lg"
    },
    on: {
      "show": _vm.showModalSeatUpdate,
      "hide": _vm.hideModalSeatUpdate
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm.dataSeatsBySegment ? _c('div', {
          class: "text-airline d-flex-center\n        ".concat(['lg', 'xl'].includes(_vm.appBreakPoint) ? 'font-medium-4' : 'font-medium-1', " fw-700")
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.flightSegment')) + ": " + _vm._s(['TR', 'JQ'].includes(_vm.bookingSource) ? _vm.segmentData.flightName : "".concat(_vm.segmentData.departure.iataCode, "-").concat(_vm.segmentData.arrival.iataCode)) + " (" + _vm._s(_vm.convertISODateTime(_vm.segmentData.departure.at, _vm.segmentData.departure.timeZone).date) + ") ")]) : _c('div', {
          staticClass: "text-airline font-medium-4 fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.selectSeat')) + " ")])];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('BButton', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(234, 84, 85, 0.15)',
            expression: "'rgba(234, 84, 85, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "outline-danger",
            "pill": "",
            "size": _vm.isMobileView ? 'sm' : 'md'
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('customer.cancel')) + " ")]), _c('BButton', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "btn-gradient",
          attrs: {
            "pill": "",
            "size": _vm.isMobileView ? 'sm' : 'md',
            "disabled": _vm.dataToSelectSeat.some(function (item) {
              return !item.hasAddedSeat && !item.alreadySeat;
            })
          },
          on: {
            "click": _vm.openSelectPayOptionModalHandle
          }
        }, [_c('span', {
          staticClass: "align-middle px-2"
        }, [_vm._v(_vm._s(_vm.$t('reservation.continue')))])])];
      }
    }])
  }, [_vm.dataSeatsBySegment ? _c('div', [_c('b-row', {
    staticClass: "flex-column flex-lg-row mx-0"
  }, [_c('b-col', {
    staticClass: "position-relative px-50"
  }, [_c('div', {
    staticClass: "position-sticky position-top-1"
  }, [_c('b-card', {
    staticClass: "shadow border-warning",
    attrs: {
      "header-bg-variant": "light-warning",
      "header-class": "p-50",
      "body-class": "p-50"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('span', {
          staticClass: "text-body fw-700"
        }, [_vm._v("Hành khách:")])];
      },
      proxy: true
    }], null, false, 162712649)
  }, _vm._l(_vm.dataToSelectSeat, function (pax, indexPax) {
    return _c('div', {
      key: pax.passengerData.paxId,
      class: "p-50 m-50 rounded position-relative\n                ".concat(_vm.indexPaxSelecting === indexPax ? 'bg-light-warning cursor-pointer border-warning' : pax.alreadySeat ? 'border' : 'hover-bg-light-secondary cursor-pointer border-secondary', "\n              "),
      on: {
        "click": function click($event) {
          _vm.indexPaxSelecting !== indexPax && !pax.alreadySeat && _vm.handleClickPax(indexPax);
        }
      }
    }, [_c('div', {
      staticClass: "fw-800 font-medium-2"
    }, [_vm._v(" " + _vm._s(pax.passengerData.lastName) + " " + _vm._s(pax.passengerData.firstName) + " ")]), _c('div', {
      staticClass: "fw-700 d-flex align-items-center gap-12px",
      class: "".concat(pax.alreadySeat ? 'text-secondary' : '')
    }, [_c('span', [_vm._v(" Số ghế: ")]), pax.seatSelected ? [_c('span', {
      class: "".concat(pax.alreadySeat ? '' : 'text-info')
    }, [_vm._v(" " + _vm._s("".concat(pax.seatSelected.row).concat(pax.seatSelected.col)) + " ")]), !pax.alreadySeat ? [_c('span', [_vm._v(" " + _vm._s(_vm.isEmpty(pax.seatSelected.fares) ? 0 : _vm.formatCurrency(pax.seatSelected.fares[0].total)) + " ")]), _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(255, 255, 255, 0.15)',
        expression: "'rgba(255, 255, 255, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "btn-icon",
      attrs: {
        "variant": "danger",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.handleDeleteSeat(pax.passengerData.paxId);
        }
      }
    }, [_c('feather-icon', {
      staticClass: "cursor-pointer",
      attrs: {
        "icon": "TrashIcon"
      }
    })], 1)] : _vm._e()] : !pax.seatSelected && _vm.indexPaxSelecting !== indexPax ? [_c('span', {
      staticClass: "text-secondary"
    }, [_vm._v("Chưa có ghế")])] : [_c('span', {
      staticClass: "text-warning"
    }, [_vm._v("Đang chọn")])]], 2)]);
  }), 0), _c('AppCollapse', {
    staticClass: "mb-1",
    attrs: {
      "type": "card"
    }
  }, [_c('AppCollapseItem', {
    attrs: {
      "title": "",
      "isVisible": "",
      "cardClass": "border"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "text-body fw-700"
        }, [_vm._v(" Chi tiết ghế ")])];
      },
      proxy: true
    }], null, false, 1133187655)
  }, _vm._l(_vm.seatInfoList, function (btn) {
    return _c('div', {
      key: btn.title,
      staticClass: "mb-50"
    }, [_c('b-button', {
      class: btn.class,
      style: ['lg', 'xl'].includes(_vm.appBreakPoint) ? "width:40px; height:40px; padding: 0;" : "width:28px; height:28px; padding: 0",
      attrs: {
        "variant": btn.variant
      }
    }), _c('span', {
      staticClass: "font-weight-bolder ml-75"
    }, [_vm._v(_vm._s(btn.title))])], 1);
  }), 0)], 1)], 1)]), _c('b-col', {
    staticClass: "px-50"
  }, [_c('div', {
    staticClass: "mb-50 fw-700 font-medium-3 text-center d-flex flex-wrap justify-content-around gap-2"
  }, [_c('div', {
    staticClass: "fw-800"
  }, [_vm._v(" " + _vm._s(_vm.getAirlineNameByCode(_vm.dataSeatsBySegment.source)) + " ")]), _c('div', [_vm._v(_vm._s(_vm.getAirCraftByIata(_vm.dataSeatsBySegment.aircraft)))])]), _c('b-card', {
    staticClass: "border-secondary-2 shadow",
    attrs: {
      "body-class": "pb-0 px-0 mb-1",
      "no-body": ""
    }
  }, _vm._l(_vm.dataSeatsBySegment.seatLayouts, function (cabinRows) {
    return _c('div', {
      key: cabinRows.id,
      staticClass: "cabinRows mb-1 d-flex flex-column",
      style: {
        alignItems: 'safe center',
        overflowX: 'auto'
      }
    }, _vm._l(cabinRows.items, function (rowSeats) {
      return _c('div', {
        key: rowSeats.id,
        staticClass: "rowSeats d-flex-center fit-content flex-nowrap mb-50 gap-1 px-50"
      }, _vm._l(rowSeats.items, function (blockSeat) {
        return _c('div', {
          key: blockSeat.id,
          staticClass: "blockSeat fw-700 d-flex-center",
          style: ['lg', 'xl'].includes(_vm.appBreakPoint) ? "height:40px; width:40px;" : "width:32px; height:32px;"
        }, [blockSeat.isLabel ? _c('div', [_vm._v(" " + _vm._s(blockSeat.name) + " ")]) : _c('b-button', {
          class: "p-0 w-100 h-100 d-flex-center fw-700 font-medium-1\n                    ".concat(blockSeat !== null && blockSeat !== void 0 && blockSeat.col && _vm.resolveLocationBySeat(blockSeat.location || blockSeat.facilities || blockSeat.seatValue || '', _vm.dataSeatsBySegment.source).includes('ExitRowSeat') ? 'exit_seat_class' : '', "\n                    "),
          attrs: {
            "id": "seat-".concat(blockSeat.id),
            "variant": _vm.resolveVariantSeat(blockSeat)
          },
          on: {
            "click": function click($event) {
              return _vm.handleClickSeat(blockSeat);
            }
          }
        }, [_vm._v(" " + _vm._s(blockSeat.col ? "".concat(blockSeat.row).concat(blockSeat.col) : ['EMPTY', 'AISLE'].includes(blockSeat) ? '' : blockSeat) + " "), blockSeat.isAvailable ? _c('b-tooltip', {
          attrs: {
            "id": "tooltip-".concat(blockSeat.id),
            "target": "seat-".concat(blockSeat.id),
            "triggers": "hover",
            "variant": "warning",
            "placement": "top",
            "boundary": "window"
          }
        }, [_c('div', {
          staticClass: "text-body fw-600"
        }, [_c('div', {
          staticClass: "text-body fw-700 font-medium-1 border-bottom"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.seatNumber')) + ": " + _vm._s(blockSeat.name) + " ")]), _c('div', [_vm._v(_vm._s(_vm.$t('flight.seatDescription')) + ":")]), _vm._l(_vm.resolveLocationBySeat(blockSeat.location || blockSeat.facilities || blockSeat.seatValue || '', _vm.dataSeatsBySegment.source), function (lct) {
          return _c('div', {
            key: lct,
            staticClass: "mb-25"
          }, [_vm._v(" - " + _vm._s(_vm.$t("flight.".concat(lct))) + " ")]);
        }), _c('div', {
          staticClass: "text-left border-top mt-1"
        }, [_c('h6', {
          staticClass: "py-25 mb-0 text-right text-dark font-weight-bolder"
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.Price')) + ": " + _vm._s(_vm.isEmpty(blockSeat.fares) ? 0 : "".concat(_vm.formatCurrency(blockSeat.fares[0].total))) + " ")])])], 2)]) : _vm._e()], 1)], 1);
      }), 0);
    }), 0);
  }), 0)], 1)], 1)], 1) : _vm._e(), _vm.bookingData ? _c('ModalAncillaryPayOptions', {
    attrs: {
      "reservations-data": _vm.bookingData,
      "is-pay-now": _vm.isPayNow,
      "has-paid-trip": _vm.hasPaidTrip,
      "place": _vm.bookingSource
    },
    on: {
      "update:isPayNow": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      },
      "update:is-pay-now": function updateIsPayNow($event) {
        _vm.isPayNow = $event;
      },
      "submit": _vm.handleConfirmModifySeat
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }