<template>
  <BModal
    id="modal-reservation-modify-seat-update"
    class="position-relative"
    body-class="px-0 py-50"
    footer-class="p-50 position-sticky position-bottom-0 bg-white"
    no-close-on-backdrop
    no-enforce-focus
    centered
    size="lg"
    @show="showModalSeatUpdate"
    @hide="hideModalSeatUpdate"
  >
    <template #modal-title>
      <div
        v-if="dataSeatsBySegment"
        :class="`text-airline d-flex-center
          ${['lg', 'xl'].includes(appBreakPoint) ? 'font-medium-4' : 'font-medium-1'} fw-700`
        "
      >
        {{ $t('flight.flightSegment') }}: {{ ['TR', 'JQ'].includes(bookingSource) ? segmentData.flightName : `${segmentData.departure.iataCode}-${segmentData.arrival.iataCode}` }}
        ({{ convertISODateTime(segmentData.departure.at, segmentData.departure.timeZone).date }})
      </div>
      <div
        v-else
        class="text-airline font-medium-4 fw-700"
      >
        {{ $t('reservation.selectSeat') }}
      </div>
    </template>
    <template #modal-footer="{close}">
      <BButton
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="outline-danger"
        pill
        :size="isMobileView ? 'sm' : 'md'"
        @click="close()"
      >
        {{ $t('customer.cancel') }}
      </BButton>
      <BButton
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        class="btn-gradient"
        pill
        :size="isMobileView ? 'sm' : 'md'"
        :disabled="dataToSelectSeat.some(item => !item.hasAddedSeat && !item.alreadySeat)"
        @click="openSelectPayOptionModalHandle"
      >
        <span class="align-middle px-2">{{ $t('reservation.continue') }}</span>
      </BButton>
    </template>

    <div v-if="dataSeatsBySegment">
      <b-row class="flex-column flex-lg-row mx-0">
        <b-col class="position-relative px-50">
          <div class="position-sticky position-top-1">
            <b-card
              header-bg-variant="light-warning"
              header-class="p-50"
              class="shadow border-warning"
              body-class="p-50"
            >
              <template #header>
                <span class="text-body fw-700">Hành khách:</span>
              </template>

              <div
                v-for="(pax, indexPax) of dataToSelectSeat"
                :key="pax.passengerData.paxId"
                :class="`p-50 m-50 rounded position-relative
                  ${indexPaxSelecting === indexPax ? 'bg-light-warning cursor-pointer border-warning' : pax.alreadySeat ? 'border' : 'hover-bg-light-secondary cursor-pointer border-secondary'}
                `"
                @click="(indexPaxSelecting !== indexPax) && !pax.alreadySeat && handleClickPax(indexPax)"
              >
                <div class="fw-800 font-medium-2">
                  {{ pax.passengerData.lastName }} {{ pax.passengerData.firstName }}
                </div>

                <div
                  class="fw-700 d-flex align-items-center gap-12px"
                  :class="`${pax.alreadySeat ? 'text-secondary' : ''}`"
                >
                  <span> Số ghế: </span>
                  <template v-if="pax.seatSelected">
                    <span :class="`${pax.alreadySeat ? '' : 'text-info'}`"> {{ `${pax.seatSelected.row}${pax.seatSelected.col}` }} </span>
                    <template v-if="!pax.alreadySeat">
                      <span> {{ isEmpty(pax.seatSelected.fares) ? 0 : formatCurrency(pax.seatSelected.fares[0].total) }} </span>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="danger"
                        size="sm"
                        class="btn-icon"
                        @click="handleDeleteSeat(pax.passengerData.paxId)"
                      >
                        <feather-icon
                          icon="TrashIcon"
                          class="cursor-pointer"
                        />
                      </b-button>
                    </template>
                  </template>

                  <template v-else-if="!pax.seatSelected && (indexPaxSelecting !== indexPax)">
                    <span class="text-secondary">Chưa có ghế</span>
                  </template>

                  <template v-else>
                    <span class="text-warning">Đang chọn</span>
                  </template>
                </div>
              </div>
            </b-card>

            <AppCollapse
              type="card"
              class="mb-1"
            >
              <AppCollapseItem
                title
                isVisible
                cardClass="border"
              >
                <template #header>
                  <div class="text-body fw-700">
                    Chi tiết ghế
                  </div>
                </template>
                <div
                  v-for="btn of seatInfoList"
                  :key="btn.title"
                  class="mb-50"
                >
                  <b-button
                    :variant="btn.variant"
                    :style="['lg', 'xl'].includes(appBreakPoint)
                      ? `width:40px; height:40px; padding: 0;`
                      : `width:28px; height:28px; padding: 0`
                    "
                    :class="btn.class"
                  />
                  <span class="font-weight-bolder ml-75">{{ btn.title }}</span>
                </div>
              </AppCollapseItem>
            </AppCollapse>
          </div>
        </b-col>

        <!-- SECTION - SEATMAP -->
        <b-col class="px-50">
          <div class="mb-50 fw-700 font-medium-3 text-center d-flex flex-wrap justify-content-around gap-2">
            <div class="fw-800">
              {{ getAirlineNameByCode(dataSeatsBySegment.source) }}
            </div>
            <div>{{ getAirCraftByIata(dataSeatsBySegment.aircraft) }}</div>
          </div>

          <b-card
            body-class="pb-0 px-0 mb-1"
            class="border-secondary-2 shadow"
            no-body
          >
            <div
              v-for="cabinRows of dataSeatsBySegment.seatLayouts"
              :key="cabinRows.id"
              class="cabinRows mb-1 d-flex flex-column"
              :style="{
                alignItems: 'safe center',
                overflowX: 'auto',
              }"
            >
              <div
                v-for="rowSeats of cabinRows.items"
                :key="rowSeats.id"
                class="rowSeats d-flex-center fit-content flex-nowrap mb-50 gap-1 px-50"
              >
                <div
                  v-for="blockSeat of rowSeats.items"
                  :key="blockSeat.id"
                  class="blockSeat fw-700 d-flex-center"
                  :style="['lg', 'xl'].includes(appBreakPoint)
                    ? `height:40px; width:40px;`
                    : `width:32px; height:32px;`
                  "
                >
                  <div v-if="blockSeat.isLabel">
                    {{ blockSeat.name }}
                  </div>

                  <!-- v-if="blockSeat.col" -->
                  <b-button
                    v-else
                    :id="`seat-${blockSeat.id}`"
                    :variant="resolveVariantSeat(blockSeat)"
                    :class="`p-0 w-100 h-100 d-flex-center fw-700 font-medium-1
                      ${blockSeat?.col && resolveLocationBySeat(blockSeat.location || blockSeat.facilities || blockSeat.seatValue || '', dataSeatsBySegment.source).includes('ExitRowSeat') ? 'exit_seat_class' : ''}
                      `"
                    @click="handleClickSeat(blockSeat)"
                  >
                    {{ blockSeat.col ? `${blockSeat.row}${blockSeat.col}` : ['EMPTY', 'AISLE'].includes(blockSeat) ? '' : blockSeat }}

                    <b-tooltip
                      v-if="blockSeat.isAvailable"
                      :id="`tooltip-${blockSeat.id}`"
                      :target="`seat-${blockSeat.id}`"
                      triggers="hover"
                      variant="warning"
                      placement="top"
                      boundary="window"
                    >
                      <div class="text-body fw-600">
                        <div class="text-body fw-700 font-medium-1 border-bottom">
                          {{ $t('flight.seatNumber') }}: {{ blockSeat.name }}
                        </div>
                        <div>{{ $t('flight.seatDescription') }}:</div>
                        <div
                          v-for="lct of resolveLocationBySeat(blockSeat.location || blockSeat.facilities || blockSeat.seatValue || '', dataSeatsBySegment.source)"
                          :key="lct"
                          class="mb-25"
                        >
                          - {{ $t(`flight.${lct}`) }}
                        </div>
                        <div class="text-left border-top mt-1">
                          <h6 class="py-25 mb-0 text-right text-dark font-weight-bolder">
                            {{ $t('flight.Price') }}:
                            {{ isEmpty(blockSeat.fares) ? 0 : `${formatCurrency(blockSeat.fares[0].total)}` }}
                          </h6>
                        </div>
                      </div>
                    </b-tooltip>
                  </b-button>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <ModalAncillaryPayOptions
      v-if="bookingData"
      :reservations-data="bookingData"
      :is-pay-now.sync="isPayNow"
      :has-paid-trip="hasPaidTrip"
      :place="bookingSource"
      @submit="handleConfirmModifySeat"
    />
  </BModal>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BCard,
  BTooltip,
} from 'bootstrap-vue'
import {
  computed,
  nextTick,
  ref,
  set,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import Vue from 'vue'

import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import i18n from '@/libs/i18n'
import store from '@/store'
import { seatInfoList, resolveLocationBySeat } from '@/components/seat-map/useSeatMapFixRender'

import { validatorPaymentPassword } from '@core/utils/validations/validators'
import { formatCurrency, convertISODateTime } from '@core/utils/filter'

import useReservationHandle from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BCard,
    BTooltip,

    AppCollapse: () => import('@core/components/app-collapse/AppCollapse.vue'),
    AppCollapseItem: () => import('@core/components/app-collapse/AppCollapseItem.vue'),

    ModalAncillaryPayOptions: () => import('@reservation/reservation-modify/components/detail/components/ModalAncillaryPayOptions.vue'),
  },
  props: {
    segmentData: {
      type: Object,
      required: true,
    },
    dataSeatsBySegment: {
      type: Object,
      required: true,
    },
    seatAlreadyChosenArr: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const {
      modifyAncillary,
      modifyReserveSeat,
      getBookingData: bookingData,
    } = useReservationHandle()

    const {
      fnAncillary,
      bookingSource,
      getAirlineBySource,
      getAirCraftByIata,
      getAirlineNameByCode,
    } = useAncillaryServicesHandle()

    const hasPaidTrip = ref(false) // trạng thái thanh toán của trip
    const isPayNow = ref(false)

    const dataToSelectSeat = ref([])
    const indexPaxSelecting = ref(-1)

    function handleClickPax(index) {
      indexPaxSelecting.value = index
    }

    function updateIndexPaxSelecting() {
      const paxIndex = dataToSelectSeat.value.findIndex(item => !item.seatSelected && !item.alreadySeat)
      if (paxIndex !== -1) indexPaxSelecting.value = paxIndex
    }

    function resolvePriceSeat(seatData) {
      if (isEmpty(seatData.seatSelected.fares)) return 0
      const findFareByPaxType = seatData.seatSelected.fares.find(fare => fare.paxType === seatData.passengerData.paxType)
      return findFareByPaxType ? findFareByPaxType.total : (seatData.seatSelected?.fares?.[0]?.total ?? 0)
    }

    const totalPricesAddSeat = computed(() => {
      const passengerEmptySeatsSelected = dataToSelectSeat.value.filter(item => item.hasAddedSeat)
      return !isEmpty(passengerEmptySeatsSelected) ? passengerEmptySeatsSelected.reduce((total, item) => total + resolvePriceSeat(item), 0) : 0
    })

    // get Price seat by ancillaryServices
    function getTotalPriceSeatByAncillaryServices(ancillaryServices = [], paxSeatData) {
      const seatData = ancillaryServices.find(item => (item.serviceType === 'SEAT')
                                                   && (item.paxId === paxSeatData.paxId)
                                                   && String(item.segmentIds[0]) === String(paxSeatData.segmentId)
                                                   && (item.pdcSeat === paxSeatData.seatNumbers))
      return seatData?.totalPrice ?? 0
    }

    function updateDataToSelect(index, seat = null, isAdd = false) {
      if (isAdd) {
        const existSeat = dataToSelectSeat.value.find(paxSeat => (paxSeat?.seatSelected?.row === seat?.row) && (paxSeat?.seatSelected?.col === seat?.col))
        if (existSeat && isAdd) return
      }
      set(dataToSelectSeat.value[index], 'seatSelected', seat)
      set(dataToSelectSeat.value[index], 'hasAddedSeat', isAdd)
    }

    function handleDeleteSeat(paxId) {
      const pIndex = dataToSelectSeat.value.findIndex(item => item.passengerData.paxId === paxId)
      updateDataToSelect(pIndex)
    }

    function handleClickSeat(seat) {
      if (!seat.isAvailable) return
      if (!isEmpty(props.seatAlreadyChosenArr)) {
        const exist = props.seatAlreadyChosenArr.find(it => it?.col === seat?.col && it?.row === seat?.row)
        if (exist) return
      }
      updateDataToSelect(indexPaxSelecting.value, seat, true)
      nextTick(() => {
        updateIndexPaxSelecting()
      })
    }

    function resolveVariantSeat(seat) {
      const selected = dataToSelectSeat.value.find(paxSeat => (paxSeat?.seatSelected?.row === seat?.row) && (paxSeat?.seatSelected?.col === seat?.col))
      const exist = props.seatAlreadyChosenArr.find(it => it?.col === seat?.col && it?.row === seat?.row)
      if (selected && !exist) return 'success'
      if (seat.isAvailable) {
        if (!exist) return 'relief-info'
      }
      return 'secondary'
    }

    function wrapPayload() {
      const seatData = []

      const dataNewAddedSeat = dataToSelectSeat.value.filter(item => item.hasAddedSeat)

      dataNewAddedSeat.forEach(item => {
        let totalPrice = 0
        let seatValue = null

        if (!isEmpty(item.seatSelected.fares)) {
          // if (item.seatSelected.fares[0].paxType === 'ALL') {
          //   totalPrice = item.seatSelected.fares[0].total
          //   seatValue = item.seatSelected.fares[0].seatValue
          // }

          const findFareByPaxType = item.seatSelected.fares.find(fare => fare.paxType === item.passengerData.paxType)
          if (findFareByPaxType) {
            totalPrice = findFareByPaxType.total
            seatValue = item.seatSelected?.seatValue || null
          } else {
            totalPrice = item.seatSelected?.fares?.[0]?.total || 0
            seatValue = item.seatSelected?.fares?.[0]?.seatValue ?? item.seatSelected?.seatValue ?? null
          }
        }

        seatData.push({
          paxId: item.passengerData.paxId,
          seatNumber: `${item.seatSelected.row}${item.seatSelected.col}`,
          segmentId: ['TR', 'JQ'].includes(bookingSource.value) ? props.segmentData.segmentIds.join(' | ') : props.segmentData.segmentId,
          boardPoint: props.segmentData.departure.iataCode,
          offPoint: props.segmentData.arrival.iataCode,
          seatValue,
          itineraryId: props.segmentData.itineraryId || null,
          total: totalPrice,
        })
      })

      return seatData
    }

    async function openSelectPayOptionModalHandle() {
      this.$bvModal.show('modal-modify-ancillary-pay-options')
    }

    const getMeEnablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])

    async function handleConfirmModifySeat() {
      let paymentPassword
      if (getMeEnablePaymentPassword.value && isPayNow.value) {
        paymentPassword = await Vue.swal({
          title: this.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          allowOutsideClick: true,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: this.$t('confirmation'),
          cancelButtonText: this.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
      }
      const paymentData = {
        isPayNow: isPayNow.value,
        paymentPassword: paymentPassword?.value || '',
      }

      if (['VN1A', 'VN1A_MT'].includes(bookingSource.value)) {
        this.$bvModal.show('modal-api-loading')

        const dataNewAddedSeat = dataToSelectSeat.value.filter(item => item.hasAddedSeat)
        const segment = props.segmentData

        const data = dataNewAddedSeat.map((item, index) => ({
          ancillaryCode: `${item.seatSelected.row}${item.seatSelected.col}`,
          ancillaryType: 'SEAT',
          ancillaryValue: item.seatSelected.seatValue,
          paxInfo: {
            type: item.passengerData.paxType,
            paxId: item.passengerData.paxId,
            firstName: item.passengerData.firstName,
            lastName: item.passengerData.lastName,
            nameId: item.passengerData?.nameId ?? undefined,
          },
          itineraries: [{
            airline: segment.airline,
            departure: segment.departure.iataCode,
            arrival: segment.arrival.iataCode,
            departureDate: convertISODateTime(segment.departure.at, segment.departure.timeZone).ISOdatetime,
            arrivalDate: convertISODateTime(segment.arrival.at, segment.arrival.timeZone).ISOdatetime,
            flightNumber: segment.flightNumber,
            segmentId: segment.segmentId,
            fareBasisCode: segment?.fareBasisCode || '',
            bookingClass: segment.bookingClass,
            groupClass: segment.groupClass,
            bookingStatus: segment.bookingStatus,
            itineraryId: segment?.itineraryId || '', // check
          }],
          quantity: 1,
          total: 1,
          operation: 'ADD',
          id: (index + 1).toString(),
        }))

        modifyAncillary(bookingData.value, data, paymentData)
          .then((() => {
            const modalShow = Array.from(window.document.querySelectorAll('.modal.show')).map(el => el.id)
            modalShow.forEach(id => { root.$root.$emit('bv::hide::modal', id) })
          }))
          .finally(() => this.$bvModal.hide('modal-api-loading'))
      } else {
        this.$bvModal.show('modal-api-loading')
        const data = await wrapPayload()
        modifyReserveSeat(bookingData.value, data, paymentData)
          .then(() => {
            const modalShow = Array.from(window.document.querySelectorAll('.modal.show'))
              .map(el => el.id).filter(id => ['modal-reservation-modify-seat-update', 'modal-modify-ancillary-pay-options'].includes(id))

            modalShow.forEach(id => { root.$root.$emit('bv::hide::modal', id) })
          })
          .finally(() => this.$bvModal.hide('modal-api-loading'))
      }
    }

    function showModalSeatUpdate() {
      hasPaidTrip.value = false

      if (['QH', 'AK', 'TH'].includes(bookingSource.value) || (['VN1A', 'VN1A_MT', 'TR', 'JQ'].includes(bookingSource.value) && !['HOLD'].includes(bookingData.value.status))) {
        isPayNow.value = true
      }

      if (['PAID'].includes(bookingData.value.status)) {
        hasPaidTrip.value = true
      }

      // NOTE - Get data table seat passengers, lấy data từ ancillaryServices
      const listPassenger = bookingData.value.paxLists.filter(p => p.paxType !== 'INFANT')
      dataToSelectSeat.value = []

      listPassenger.forEach(passenger => {
        let seatSelected = null
        let alreadySeat = false

        if (!isEmpty(passenger.seats)) {
          const hasSeatInSegment = passenger.seats.find(s => (s.paxId === passenger.paxId) && (String(s.segmentId) === String(props.segmentData.devAddonsSegmentIndex)))
          if (hasSeatInSegment) {
            alreadySeat = true
            seatSelected = {
              row: hasSeatInSegment.seatNumbers.slice(0, -1),
              col: hasSeatInSegment.seatNumbers.slice(-1),
              fares: [
                {
                  total: getTotalPriceSeatByAncillaryServices(bookingData.value.ancillaryServices, hasSeatInSegment),
                },
              ],
            }
          }
        }

        dataToSelectSeat.value.push({
          passengerData: passenger,
          hasAddedSeat: false,
          seatSelected,
          alreadySeat,
        })
      })

      updateIndexPaxSelecting()
    }

    function hideModalSeatUpdate() {
      dataToSelectSeat.value = []
    }

    return {
      bookingData,
      totalPricesAddSeat,
      fnAncillary,
      bookingSource,
      getAirlineBySource,
      getAirCraftByIata,
      getAirlineNameByCode,
      formatCurrency,
      isEmpty,
      convertISODateTime,
      indexPaxSelecting,

      // handleSelectSeat,
      handleDeleteSeat,
      handleClickPax,

      handleClickSeat,

      showModalSeatUpdate,
      hideModalSeatUpdate,

      dataToSelectSeat,
      seatInfoList,

      openSelectPayOptionModalHandle,
      handleConfirmModifySeat,
      resolveVariantSeat,

      hasPaidTrip,
      isPayNow,

      resolveLocationBySeat,
    }
  },
}
</script>

<style lang="scss" scoped>
.exit_seat_class {
  position: relative;
}
.exit_seat_class::after {
  content: "";
  position: absolute;
  bottom: -.9px;
  left: 0;
  right: 0;
  width: 100%;
  height: 6px;
  background-color: #EF5350;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
</style>
