<template>
  <b-modal
    id="modal-modify-ancillary-seat-details"
    title="Chi tiết chỗ ngồi"
    title-class="text-airline font-weight-bolder font-medium-2"
    header-class="p-75"
    footer-class="p-50"
    no-close-on-backdrop
    no-enforce-focus
    centered
    size="lg"
    @hide="hideModalSeatDetails"
  >
    <!-- ANCHOR - Footer: Button -->
    <template #modal-footer="{close}">
      <div class="w-100 d-flex-center">
        <b-button
          v-ripple.400="'rgba(234, 84, 85, 0.15)'"
          variant="outline-secondary"
          pill
          @click="close()"
        >
          {{ $t('reservation.back') }}
        </b-button>
      </div>
    </template>

    <div style="min-height: 200px;">
      <b-card
        v-for="(segment, indexSegment) of resolveSegmentsAddSeat(reservationsData.itineraries.flat())"
        :key="indexSegment"
        header-bg-variant="light-info"
        header-class="py-1 mb-0 justify-content-center"
        body-class="pb-0 px-0 mb-1"
        class="border shadow"
      >
        <template #header>
          <div class="text-body fw-700">
            {{ $t('flight.flightSegment') }}:
            {{ ['TR', 'JQ'].includes(bookingSource) ? segment.flightName : `${segment.departure.iataCode} -${segment.arrival.iataCode}` }}
            <small class="text-info fw-700">
              ({{ convertISODateTime(segment.departure.at, segment.departure.timeZone).date }})
            </small>
          </div>
        </template>

        <!-- status trong segment là CKIN thì ko cho mua hành lý, seat (https://discord.com/channels/1054696448110903327/1299932651280662528/1300367492027518996) -->
        <BAlert
          v-if="segment.status === 'CKIN'"
          show
          variant="danger"
          class="text-danger px-2 py-50 fw-700 my-50"
        >
          Vé đã check-in không thể thêm chỗ ngồi
        </BAlert>
        <b-table
          centered
          hover
          responsive
          bordered
          small
          no-border-collapse
          class="mb-0 mt-25 px-25"
          thead-class="text-nowrap text-center"
          tbody-class="text-nowrap"
          :fields="seatDetailsField(bookingSource)"
          :items="resolveSsrData(segment)"
        >
          <template
            v-for="(column, indexColumn) in seatDetailsField(bookingSource)"
            #[`head(${column})`]="data"
          >
            <span :key="indexColumn">
              {{ $t(`reservation.${data.column}`) }}
            </span>
          </template>

          <template #table-colgroup="scope">
            <col
              v-for="field in scope.fields"
              :key="field.key"
              :style="{
                width: field.key === 'seat' ? '120px' : '180px',
                'max-width': field.key === 'seat' ? '120px' : '400px',
              }"
            >
          </template>

          <template #cell(passenger)="{ index, item }">
            <small class="font-weight-bolder text-warning">
              {{ index + 1 }}
            </small>
            <span>
              {{ item.passenger.lastName }} {{ item.passenger.firstName }} {{ item.passenger.title || '' }}
            </span>
          </template>

          <template #cell(seatNum)="{ item }">
            <div
              v-if="isEmpty(item.ancillaryServices)"
              class="text-center"
            >
              -
            </div>

            <div
              v-else
              class="d-flex-center"
            >
              <!-- style="min-width: 45px; min-height: 40px;" -->
              <b-button
                variant="success"
                class="px-50 text-nowrap fw-700"
                size="sm"
                disabled
              >
                {{ item.ancillaryServices[0].pdcSeat }}
              </b-button>
            </div>
          </template>

          <template #cell(status)="{ item }">
            <div
              v-if="isEmpty(item.ancillaryServices)"
              class="text-center"
            >
              -
            </div>

            <div
              v-else
              class="font-weight-bolder font-small-4 text-center"
              :class="item.ancillaryServices[0].status === 'HI' ? 'text-success' : item.ancillaryServices[0].status === 'HD' ? 'text-warning' : 'text-dark'"
            >
              {{ STATUS_NAME_ANCILLARY[item.ancillaryServices[0].status] || item.ancillaryServices[0].status }}
            </div>
          </template>

          <template #cell(total)="{ item }">
            <div
              v-if="isEmpty(item.ancillaryServices)"
              class="text-right py-50"
            >
              0
            </div>
            <div
              v-else
              class="font-weight-bold text-right"
            >
              {{ formatCurrency(item.ancillaryServices[0].totalPrice) }}
            </div>
          </template>

          <!-- <template #cell(action)="{ item }">
            <div v-if="fnAncillary.SEAT.delete && !isEmpty(item.ancillaryServices) && item.ancillaryServices[0].status !== 'HI'">
              <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="flat-danger"
                class="btn-icon rounded-pill d-flex justify-content-center align-items-center"
                size="sm"
                @click="event => handleRemoveSeat(reservationsData, item.ancillaryServices[0], event)"
              >
                <feather-icon
                  icon="Trash2Icon"
                  class="mr-25"
                />
                <span style="padding-top: 1px">
                  Huỷ chỗ ngồi
                </span>
              </b-button>
            </div>
          </template> -->
        </b-table>

        <div class="w-100 text-right mt-50">
          <b-button
            v-ripple.400="'rgba(0, 207, 232, 0.15)'"
            class="btn-icon my-50 mr-50 p-50"
            variant="outline-info"
            pill
            :disabled="segment.status === 'CKIN'"
            @click="openModalEditSeatHandle(segment)"
          >
            <div class="d-flex-center px-1">
              <feather-icon
                icon="PlusIcon"
                size="20"
                class="mr-25"
              />
              <span>Chọn chỗ ngồi</span>
            </div>
          </b-button>
        </div>
      </b-card>
    </div>

    <ModalSeatUpdate
      v-if="segmentData && dataSeatsBySegment"
      :segment-data="segmentData"
      :seat-already-chosen-arr="seatAlreadyChosenArr"
      :data-seats-by-segment="dataSeatsBySegment"
    />
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  BTable,
  BCard,
  BAlert,
} from 'bootstrap-vue'
import {
  computed,
  ref,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import { groupBy } from 'lodash-es'

import { convertISODateTime, formatCurrency } from '@/@core/utils/filter'
import store from '@/store'

import { getShortNameTripByMultiSegments } from '@core/utils/utils'

import useReservation from '@reservation/useReservationHandle'
import useAncillaryServicesHandle from '@reservation/reservation-modify/components/detail/ancillary-services/useAncillaryServicesHandle'

import useToast from '@useToast'

import ModalSeatUpdate from './ModalSeatUpdate.vue'

export default {
  components: {
    BButton,
    BModal,
    BTable,
    BCard,
    ModalSeatUpdate,
    BAlert,
  },
  props: {
    reservationsData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { toastError } = useToast()

    const {
      getSeatMapData,
      fetchSeatMap,
      delay,
      STATUS_NAME_ANCILLARY,
    } = useReservation()

    const {
      fnAncillary,
      bookingSource,
    } = useAncillaryServicesHandle()

    const dataPaxInTrip = ref({})
    const paxListsNoInfant = computed(() => props.reservationsData.paxLists.filter(p => p.paxType !== 'INFANT'))

    const SOURCE_HAS_ACTION_SEAT = ['1S', '1S_CTRL']

    function seatDetailsField(source) {
      const fields = ['passenger', 'seatNum', 'status', 'total']
      if (SOURCE_HAS_ACTION_SEAT.includes(source)) fields.push('action')
      return fields
    }

    function resolveSegmentsAddSeat(segmentLists) {
      if (!['TR', 'JQ'].includes(bookingSource.value)) {
        return segmentLists
      }

      const result = []
      const groupDataSegment = groupBy(segmentLists, segment => segment.segmentId.slice(0, -1))

      const pushItem = segmentArr => {
        result.push({
          ...segmentArr[0],
          flightName: getShortNameTripByMultiSegments(segmentArr),
          flights: segmentArr.map(it => `${it.departure.iataCode}-${it.arrival.iataCode}`).join(' | '),
          segmentIds: segmentArr.map(it => it.segmentId),
        })
      }

      Object.values(groupDataSegment).forEach(segmentIdLists => {
        pushItem(segmentIdLists)
      })

      return result
    }

    function getAncillaryServices(anc, segment, pax) {
      const anciSeat = anc.filter(item => (item.serviceType === 'SEAT') && (item.paxId === pax.paxId) && (item.segmentIds[0] === segment.segmentId))
      if (!isEmpty(anciSeat) && ['VU'].includes(bookingSource.value)) {
        const mapPdcSeat = anciSeat.map(seatItem => {
          const paxSeat = pax.seats.find(s => (s.paxId === seatItem.paxId) && (s.segmentId === seatItem.segmentIds[0]))
          if (paxSeat) {
            return {
              ...seatItem,
              pdcSeat: paxSeat.seatNumbers,
            }
          }
          return seatItem
        })
        return mapPdcSeat
      }
      return anciSeat
    }

    function resolveSsrData(segment) {
      const { ancillaryServices } = props.reservationsData
      const data = []
      paxListsNoInfant.value.forEach((p, pIndex) => {
        data.push({
          passenger: {
            ...p,
            passengerIndex: pIndex + 1,
          },
          ancillaryServices: getAncillaryServices(ancillaryServices, segment, p),
        })
      })

      return data
    }

    //  ANCHOR - REMOVE SEAT ===================
    // function handleRemoveSeat(bookingData, seatData, bvModalEvent) {
    //   bvModalEvent.preventDefault()
    //   const payload = {
    //     paxId: seatData.paxId,
    //     segmentId: seatData.segmentIds[0],
    //     seatNumber: seatData.pdcSeat,
    //   }
    //   const titleMsg = () => this.$createElement('div', {
    //     domProps: {
    //       innerHTML:
    //       `
    //     Xác nhận huỷ chỗ ngồi:
    //       <span class="text-warning font-weight-bolder">${seatData.pdcSeat}</span> ?
    //       `,
    //     },
    //   })
    //   this.$bvModal
    //     .msgBoxConfirm(titleMsg(), {
    //       title: this.$t('modal.confirm'),
    //       size: 'sm',
    //       okVariant: 'danger',
    //       okTitle: 'Huỷ chỗ ngồi',
    //       cancelTitle: this.$t('reservation.back'),
    //       cancelVariant: 'outline-secondary',
    //       hideHeaderClose: true,
    //       centered: true,
    //     })
    //     .then(value => {
    //       if (value) {
    //         this.$bvModal.show('modal-api-loading')

    //         modifyCancelSeat(bookingData, payload)
    //           .then(() => {
    //             // store.dispatch('app-reservation/removeSeatMapData')
    //           })
    //           .finally(() => {
    //             this.$bvModal.hide('modal-api-loading')
    //           })
    //       }
    //     })
    // }

    const segmentData = ref() // data segment
    const dataSeatsBySegment = ref() // data seat by segment
    const seatAlreadyChosenArr = ref([]) // list các chỗ đã chọn (có source ko update ngay availability của response /flights/seat-map)

    async function openModalEditSeatHandle(segment) {
      seatAlreadyChosenArr.value = []
      segmentData.value = {
        ...segment,
        flights: ['TR', 'JQ'].includes(bookingSource.value) ? segment.flights : `${segment.departure.iataCode}-${segment.arrival.iataCode}`,
        devAddonsSegmentIndex: segment.airline === 'QH' ? segment.segmentId : segment.devAddonsSegmentIndex,
      }

      const allPaxHasSeat = paxListsNoInfant.value.every(pax => !isEmpty(pax.seats) && pax.seats.some(seat => seat.segmentId === String(segmentData.value.devAddonsSegmentIndex)))
      if (allPaxHasSeat) {
        toastError({
          title: 'messagesList.error',
          content: 'Tất cả hành khách đã có chỗ ngồi trên hành trình này.',
        })
        return
      }

      if (isEmpty(getSeatMapData.value)) {
        try {
          this.$bvModal.show('modal-api-loading')
          await fetchSeatMap(props.reservationsData)
        } catch (error) {
          console.error(error)
        } finally {
          this.$bvModal.hide('modal-api-loading')
        }
      }

      // get data seat already chosen
      props.reservationsData.paxLists.forEach(p => {
        if (!isEmpty(p.seats)) {
          p.seats.forEach(seat => {
            if (seat.segmentId === String(segmentData.value.devAddonsSegmentIndex)) {
              seatAlreadyChosenArr.value.push({
                ...seat,
                row: seat.seatNumbers.slice(0, -1),
                col: seat.seatNumbers.slice(-1),
              })
            }
          })
        }
      })

      // get dataSeatsBySegment
      dataSeatsBySegment.value = getSeatMapData.value.find(item => item.flights === segmentData.value.flights && (['VN1A', 'VN1A_MT'].includes(bookingSource.value) ? (item.itineraryId === segmentData.value.devItineraryIndex) : true))

      if (isEmpty(dataSeatsBySegment.value)) {
        toastError({
          title: 'messagesList.error',
          content: 'Lỗi tải dữ liệu sơ đồ chỗ ngồi. Vui lòng liên hệ booker để được hỗ trợ.',
        })
        return
      }

      await delay(200)
      this.$bvModal.show('modal-reservation-modify-seat-update')
    }

    function hideModalSeatDetails() {
      segmentData.value = undefined
      store.dispatch('app-reservation/removeSeatMapData')
    }

    return {
      isEmpty,
      fnAncillary,
      bookingSource,
      seatDetailsField,
      convertISODateTime,

      hideModalSeatDetails,

      dataPaxInTrip,
      STATUS_NAME_ANCILLARY,

      segmentData,
      seatAlreadyChosenArr,
      dataSeatsBySegment,

      formatCurrency,
      resolveSsrData,
      openModalEditSeatHandle,

      resolveSegmentsAddSeat,
    }
  },
}
</script>
